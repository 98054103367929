import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['latitude', 'longitude', 'input', "submit"];

    geolocate() {
        if (navigator.geolocation) {
            this.#disableForm();
            this.inputTarget.value = "";
            this.inputTarget.placeholder = "Detecting location...";
            navigator.geolocation.getCurrentPosition(async (position) => {
                const success = await this.#setLocation(position);
                this.#enableForm();
                if(success){
                    this.submit();
                }

            });
        } else {
            window.alert("Geolocation is not supported by this browser.");
        }
    }

    submit() {
        this.element.requestSubmit();
    }

    removeLatLong() {
        this.latitudeTarget.value = "";
        this.longitudeTarget.value = "";
    }

    #disableForm(){
        this.submitTargets.forEach((submit) => {
            submit.disabled = true;
        });
        this.inputTarget.disabled = true;
    }

    #enableForm(){
        this.submitTargets.forEach((submit) => {
            submit.disabled = false;
        });
        this.inputTarget.disabled = false;
    }



    async #setLocation(position) {
        const {latitude, longitude} = position.coords;
        this.latitudeTarget.value = latitude;
        this.longitudeTarget.value = longitude;
        return this.#reverseGeocode(latitude, longitude);

    }

    async #reverseGeocode(latitude, longitude) {
        const latLng = new google.maps.LatLng(latitude, longitude);
        const geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            geocoder.geocode({'latLng': latLng}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.inputTarget.value = results[0].formatted_address;
                    resolve(true);
                } else {
                    this.inputTarget.value = 'Unknown';
                    reject(false);
                }
            });
        });
    }

}
