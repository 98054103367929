import { Controller } from '@hotwired/stimulus';
// This controller is meant to be applied very high up in the dom, so that you can handle triggering modals "anywhere"
// Note the assumption is that you are using the native <dialog> element to use this controller.
// There's a partial to help with this: app/views/components/_modal.slim
export default class extends Controller {
    static targets = ['modal'];

    open({params: {id}}){
        const modal = this.#findModalById(id);
        if(modal){
            modal.showModal();
        } else {
            console.log(`Modal with id ${id} not found`);
        }
    }

    close({params: {id}}){
        const modal = this.#findModalById(id);
        if(modal){
            modal.close();
        } else {
            console.log(`Modal with id ${id} not found`);
        }
    }

    #findModalById(id){
        return this.modalTargets.find((modal) => modal.id === id);
    }
}
