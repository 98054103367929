import { Controller } from '@hotwired/stimulus';
// This reports some analytics data when a location is returned from the search or clicked on
// note that it relies on the global locationMetrics object being available. See the file app/assets/javascripts/location_metrics.js
export default class extends Controller {
    static values = {
        locationIds: Array
    }
    connect(){
        this.#recordImpressions();
    }

    #recordImpressions(){
        if(this.hasLocationIdsValue){
            locationMetrics.recordImpression(this.locationIdsValue);
        }
    }

    recordClick({params: {id}}){
        locationMetrics.recordClick(id);
    }

}
