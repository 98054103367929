import {Controller} from '@hotwired/stimulus';

// This controller is very tied to the markup, see the _search-near-me partial for an example
export default class extends Controller {

    static targets = ['label', 'option'];
    static values = {
        placeholder: String
    };

    connect() {
        this.#updateLabel();
        this.boundClickOutsideToClose = this.#clickOutsideToClose.bind(this);
    }

    toggle(event) {
        const currentValue = this.element.getAttribute('aria-expanded');
        const newValue = currentValue === 'true' ? 'false' : 'true';
        this.element.setAttribute('aria-expanded', newValue);
        if (newValue === 'true') {
            this.#onOpen();
        } else {
            this.#onClose();
        }
    }

    updateChecked(event){
        const currentState = event.target.checked;
        const li = event.target.closest('li');
        li.setAttribute('aria-selected', currentState);
        this.#updateLabel();
    }

    #updateLabel() {
        const selectedValues = this.optionTargets.filter(option => option.checked).map(option => option.closest('label').textContent.trim());
        if (selectedValues.length > 0) {
            this.labelTarget.textContent = selectedValues.join(', ');
        } else {
            this.labelTarget.textContent = this.placeholderValue;
        }
    }

    #onOpen() {
        document.body.addEventListener('click', this.boundClickOutsideToClose, {
            capture: true,
        });
    }

    #onClose() {
        document.body.removeEventListener('click', this.boundClickOutsideToClose, {
            capture: true,
        });
    }

    #clickOutsideToClose(event) {
        if (!event.target.closest('.multi-select')) {
            this.toggle(event);
        }
    }
}
